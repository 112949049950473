export const addScriptToBody = (src: string, async = true, defer = true) => {
  const script = document.createElement("script");
  script.src = src;
  script.async = async;
  script.defer = defer;
  document.body.appendChild(script);

  // Return a function to remove the script
  return () => {
    document.body.removeChild(script);
  };
};

export const appendToBodyIfNotExists = (tagName: string, id: string) => {
  let element = document.getElementById(id);

  if (!element) {
    element = document.createElement(tagName);
    element.id = id;
    document.body.appendChild(element);
  }

  return element;
};

export const isClickOutside = <T extends HTMLElement>(
  ref: React.RefObject<T>,
  event: MouseEvent
): boolean => {
  const refElement = ref.current;
  if (refElement) {
    const { left, top, width, height } = refElement.getBoundingClientRect();
    const { clientX, clientY } = event;

    if (
      clientX < left ||
      clientX > left + width ||
      clientY < top ||
      clientY > top + height
    ) {
      return true;
    }
  }

  return false;
};

const getTranslateValues = (transformValue: string) => {
  // Remove decimal part from transformValue
  const transformedValue = transformValue.replace(
    /(-?\d+\.\d+)px/g,
    (_, p1) => `${Math.floor(Number(p1))}px`
  );

  const regex = /translate\((-?\d+)px, (-?\d+)px\)/;
  const match = transformedValue.match(regex);

  if (match) {
    const [, translateX, translateY] = match;
    return [Number(translateX), Number(translateY)];
  }
  return [0, 0];
};

export const translateElementToVisibleArea = <T extends HTMLElement>(
  ref: React.RefObject<T>
): void => {
  const refElement = ref.current;
  if (!refElement) {
    return;
  }

  // Get the current translate values
  const [currentTranslateX, currentTranslateY] = getElementTransformValues(ref);

  const { left, top, width, height } = refElement.getBoundingClientRect();

  const { innerWidth, innerHeight } = window;

  // Calculate the new translate values to bring the element back into the screen area
  let newTranslateX = currentTranslateX;
  let newTranslateY = currentTranslateY;

  if (left < 0) {
    newTranslateX = currentTranslateX - left;
  } else if (left + width > innerWidth) {
    newTranslateX = currentTranslateX - (left + width - innerWidth);
  }

  if (top < 0) {
    newTranslateY = currentTranslateY - top;
  } else if (top + height > innerHeight) {
    newTranslateY = currentTranslateY - (top + height - innerHeight);
  }

  // Apply the new translate values
  refElement.style.transform = `translate(${newTranslateX}px, ${newTranslateY}px)`;

  return;
};

export const getElementTransformValues = <T extends HTMLElement>(
  ref: React.RefObject<T>
): number[] => {
  const refElement = ref.current;
  if (!refElement) {
    return [0, 0];
  }

  // Get the current translate values
  const currentTransform = refElement.style.transform;
  return currentTransform ? getTranslateValues(currentTransform) : [0, 0];
};

export const setElementTransform = <T extends HTMLElement>(
  ref: React.RefObject<T>,
  translateValues: number[]
) => {
  const refElement = ref.current;
  if (!refElement) {
    return;
  }

  const [translateX, translateY] = translateValues;
  refElement.style.transform = `translate(${translateX}px, ${translateY}px)`;
};

export const calculateDistance = (
  [x1, y1]: [number, number],
  [x2, y2]: [number, number]
): number => {
  const distance = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
  return distance;
};
