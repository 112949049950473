import "./assets/index.css";
import "@/i18n/config.ts";

import React from "react";
import ReactDOM from "react-dom/client";

import ChatBubble from "./components/ChatBubble/index.tsx";
import { appendToBodyIfNotExists } from "./utils/utils.ts";

const chatbotContainer = appendToBodyIfNotExists("div", "chatbot-container");

ReactDOM.createRoot(chatbotContainer).render(
  <React.StrictMode>
    <ChatBubble />
  </React.StrictMode>
);
