import { fetcher } from "@/utils/fetcher";

import useAccessToken from "./useAccessToken";

const useAPI = () => {
  const { getAccessToken } = useAccessToken();

  const fetcherGetPic = async (
    collection_id: string
  ): Promise<any> => {
    const token = await getAccessToken();

    return fetcher.get("/chat/collection", {
      params: {
        collection_id,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  return {
    fetcherGetPic,
  };
};
export default useAPI;
