import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";

import { BASE_URL, TOKEN } from "../constants/constants";
const isDevMode = process.env.NODE_ENV === "development"; // 检查是否为开发环境
export const baseURL = isDevMode
  ? import.meta.env.VITE_APP_TEST_BASE_URL // 开发环境下使用的 URL
  : import.meta.env.VITE_APP_BASE_URL; // 其他环境下使用的 URL

// set axios auth header
axios.defaults.headers.common["Authorization"] = TOKEN;

export const getChatHistory = async (user_id: string) => {
  const { data } = await axios.get(`${BASE_URL}/history/${user_id}`);
  return data;
};

interface ErrorResponse {
  message: string;
}

export const fetcher: AxiosInstance = axios.create({
  baseURL: baseURL,
});

fetcher.interceptors.response.use(
  (resp: AxiosResponse) => {
    if (resp.status >= 200 && resp.status <= 300) {
      return resp.data;
    }
    const error: ErrorResponse = {
      message: resp.data?.message || "Server failed.",
    };
    return Promise.reject(error);
  },
  (err: AxiosError<{ detail?: string; message?: string }>) => {
    const message =
      err?.response?.data?.detail ||
      err?.response?.data?.message ||
      err?.message ||
      "Server error";

    // if (typeof message === "string") {
    //   toast.error(message);
    // } else {
    //   toast.error(i18next.t("Something went wrong"));
    // }
    throw { ...err, message };
  }
);
