import { type UseChatHelpers } from "ai/react";

import { Button } from "../components/ui/button";
import { PromptForm } from "../components/prompt-form";
import { ButtonScrollToBottom } from "../components/button-scroll-to-bottom";
import { IconRefresh, IconStop } from "../components/ui/icons";
import { FooterText } from "../components/footer";

export interface ChatPanelProps
  extends Pick<
    UseChatHelpers,
    | "append"
    | "isLoading"
    | "reload"
    | "messages"
    | "stop"
    | "input"
    | "setInput"
  > {
  id?: string;
}

function isIOSAndSafari() {
  const userAgent = window.navigator.userAgent;
  const isAppleDevice = /iPad|iPhone|iPod/.test(userAgent);
  const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);

  return isAppleDevice && isSafari;
}

export function ChatPanel({
  id,
  isLoading,
  stop,
  append,
  reload,
  input,
  setInput,
  messages,
}: ChatPanelProps) {
  return (
    <div className="from-muted/10 to-muted/30   bg-gradient-to-b from-10% to-50% ">
      <ButtonScrollToBottom />
      <div className="mx-auto sm:max-w-2xl sm:px-4">
        <div className="flex h-10 items-center justify-center">
          {isLoading ? (
            <Button
              variant="outline"
              onClick={() => stop()}
              className="bg-background"
            >
              <IconStop className="mr-2" />
              Stop generating
            </Button>
          ) : (
            messages?.length > 0 && (
              <div>
                {/* <Button
                  variant="outline"
                  onClick={() => reload()}
                  className="bg-background"
                >
                  <IconRefresh className="mr-2" />
                  Regenerate response
                </Button> */}
                <Button
                  variant="outline"
                  onClick={() => {
                    localStorage.removeItem(
                      "session_id_" + window.chatGPTBConfig["collection"]
                    );
                    localStorage.removeItem(
                      "messages_" + window.chatGPTBConfig["collection"]
                    );

                    location.reload();
                  }}
                  className="bg-background ml-2"
                >
                  <IconRefresh className="mr-2" />
                  Clear
                </Button>
              </div>
            )
          )}
        </div>
        <div className="bg-background space-y-4 border-t px-4 py-2 shadow-lg sm:rounded-t-xl sm:border md:py-4">
          <PromptForm
            onSubmit={async (value) => {
              await append({
                id,
                content: value,
                role: "user",
              });
            }}
            input={input}
            setInput={setInput}
            isLoading={isLoading}
          />
          <FooterText className="hidden sm:block" />
        </div>
      </div>
    </div>
  );
}
