import { useEffect, useState } from "react";
import { type Message } from "ai";

import { Separator } from "../components/ui/separator";
import { ChatMessage } from "../components/chat-message";
import useAPI from "@/hooks/useAPI";

export interface ChatList {
  messages: Message[];
}
const collectionId = window.chatGPTBConfig["collection"]

export function ChatList({ messages }: ChatList) {
  console.log("fetcherGetPic");
  const [imageUrl, setImageUrl] = useState("");
  const { fetcherGetPic } = useAPI();
  useEffect(() => {
    const fetchData = async () => {
        if (collectionId) {
            const resp = await fetcherGetPic(collectionId);
            if (resp && resp.code === 0) {
              // setImageUrl(resp.data.logo ? resp.data.logo: svgDefaultLogo);
              setImageUrl(resp.data.logo);
            }
        }
    };
    fetchData();
  }, [collectionId]);

  if (!messages.length) {
    return null;
  }

  return (
    <div className="hide-scrollbar relative mx-auto max-w-2xl   ">
      {messages.map((message, index) => (
        <div key={index}>
          <ChatMessage userIcon={imageUrl} message={message} />
          {index < messages.length - 1 && (
            <Separator className="my-4 md:my-8" />
          )}
        </div>
      ))}
    </div>
  );
}
