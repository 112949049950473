export const opacityTransition = (
  setStyle: (style: string) => void,
  show: boolean
): void => {
  if (show) {
    setStyle("opacity-0");
    setTimeout(() => {
      setStyle("opacity-100");
    }, 0);
  } else {
    setStyle("hidden");
  }
};
