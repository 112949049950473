// Inspired by Chatbot-UI and modified to fit the needs of this project
// @see https://github.com/mckaywrigley/chatbot-ui/blob/main/components/Chat/ChatMessage.tsx

import { Message } from "ai";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";

import { cn } from "../lib/utils";
import { CodeBlock } from "../components/ui/codeblock";
import { MemoizedReactMarkdown } from "../components/markdown";
import { IconOpenAI, IconUser } from "../components/ui/icons";
import { ChatMessageActions } from "../components/chat-message-actions";

export interface ChatMessageProps {
  userIcon: any;
  message: Message;
}

export function ChatMessage({ userIcon, message, ...props }: ChatMessageProps) {
  return (
    <div
      className={cn(" group relative mb-4 mr-8 flex items-start")}
      {...props}
    >
      <div
        className={cn(
          "flex h-8 w-8 shrink-0 select-none items-center justify-center rounded-md border shadow",
          message.role === "user"
            ? "bg-background"
            : "bg-primary text-primary-foreground"
        )}
      >
        {/* {message.role === "user" ? (userIcon ? <img src={userIcon} className="image-fixed" />: <IconUser /> ): <IconOpenAI />} */}
        {message.role === "user" ? (
          <IconUser />
        ) : userIcon ? (
          <img src={userIcon} className="image-fixed" />
        ) : (
          <IconOpenAI />
        )}
      </div>
      <div className="ml-4 flex-1 space-y-2 overflow-hidden px-1">
        <MemoizedReactMarkdown
          className="prose dark:prose-invert prose-p:leading-relaxed prose-pre:p-0 break-words"
          remarkPlugins={[remarkGfm, remarkMath]}
          components={{
            p({ children }) {
              return <p className="mb-2 last:mb-0">{children}</p>;
            },
            code({ node, inline, className, children, ...props }) {
              if (children.length) {
                if (children[0] == "▍") {
                  return (
                    <span className="mt-1 animate-pulse cursor-default">▍</span>
                  );
                }

                children[0] = (children[0] as string).replace("`▍`", "▍");
              }

              const match = /language-(\w+)/.exec(className || "");

              if (inline) {
                return (
                  <code className={className} {...props}>
                    {children}
                  </code>
                );
              }

              return (
                <CodeBlock
                  key={Math.random()}
                  language={(match && match[1]) || ""}
                  value={String(children).replace(/\n$/, "")}
                  {...props}
                />
              );
            },
          }}
        >
          {message.content}
        </MemoizedReactMarkdown>
        <ChatMessageActions message={message} />
      </div>
    </div>
  );
}
