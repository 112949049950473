import * as React from "react";
import { baseURL } from "../../../../utils/fetcher";
import { Message } from "ai";

async function createSession(collection_id: string) {
  try {
    const response = await fetch(`${baseURL}/chat/session`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        collection_id: collection_id,
      }),
    });
    console.log(response);

    if (!response.ok) {
      throw new Error("Failed to send chat message");
    }

    const data = await response.json();

    // 在这里处理响应数据
    console.log(data);
    return data.data;

    // 如果需要，你可以在这里执行一些其他操作，例如清空输入框等
  } catch (error) {
    // 处理错误，例如显示错误消息或记录日志
    console.error("Error sending chat message:", error);
  }
}

async function sendMessage(question: string, session_id: string) {
  try {
    const response = await fetch(`${baseURL}/chat/ask`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        question: question,
        session_id: session_id,
      }),
    });
    console.log(response);

    if (!response.ok) {
      throw new Error("Failed to send chat message");
    }

    const data = await response.json();

    // 在这里处理响应数据
    console.log(data);
    return data;

    // 如果需要，你可以在这里执行一些其他操作，例如清空输入框等
  } catch (error) {
    // 处理错误，例如显示错误消息或记录日志
    console.error("Error sending chat message:", error);
  }
}

export function useChat() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [input, setInput] = React.useState("");
  const [messages, setMessages] = React.useState<Message[]>(() => {
    // 尝试从localStorage中获取messages
    const savedMessages = localStorage.getItem(
      "messages_" + window.chatGPTBConfig["collection"]
    );
    // 如果有savedMessages，则解析它们，否则设置一个空数组
    return savedMessages ? JSON.parse(savedMessages) : [];
  });
  const messagesRef = React.useRef(messages);
  const bottomRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    messagesRef.current = messages;
    console.log(messages, "messages");
    localStorage.setItem(
      "messages_" + window.chatGPTBConfig["collection"],
      JSON.stringify(messages)
    );
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const append = React.useCallback(
    async (message: any) => {
      try {
        // 将新消息添加到消息列表中
        setMessages(messagesRef.current.concat(message));
        setIsLoading(true);

        // 将 messages 转换为 JSON 字符串
        // const requestBody = JSON.stringify({
        //   content: message.content,
        //   messages: messagesRef.current,
        // });

        // const response = await fetch(
        //   "https://azurecustomdataai-abouthomeloving.replit.app/azure_chat",
        //   {
        //     method: "POST", // 根据你的API路由定义的请求方法设置为GET或其他方法
        //     mode: "cors",
        //     headers: {
        //       "Content-Type": "application/json", // 根据你的API要求设置请求头
        //     },
        //     body: requestBody,
        //   }
        // );
        const collection_id = window.chatGPTBConfig["collection"];
        let session_id;
        if (
          localStorage.getItem(
            "session_id_" + window.chatGPTBConfig["collection"]
          )
        ) {
          session_id = localStorage.getItem(
            "session_id_" + window.chatGPTBConfig["collection"]
          );
        } else {
          const create_result = await createSession(collection_id);
          session_id = create_result["session_id"];
          localStorage.setItem(
            "session_id_" + window.chatGPTBConfig["collection"],
            session_id
          );
        }

        console.log(session_id);

        const response = await sendMessage(message.content, session_id);

        const reqJson = response;
        console.log(reqJson, "reqJson");

        const newMessage: Message = {
          id: "1",
          content: reqJson.data[1].content,
          role: "assistant",
        };
        setMessages(messagesRef.current.concat(newMessage));

        setIsLoading(false);
        // 返回一个成功的 Promise
        return Promise.resolve("1");
      } catch (error) {
        // 处理错误情况
        console.error("Error in append:", error);
        setIsLoading(false);
        // 返回一个失败的 Promise，可以根据需要更改
        return Promise.reject(error);
      }
    },
    [setMessages]
  );

  const reload = async () => {
    const response = await fetch("https://herenciax.vercel.app/api/test", {
      method: "GET", // 根据你的API路由定义的请求方法设置为GET或其他方法
      headers: {
        "Content-Type": "application/json", // 根据你的API要求设置请求头
      },
    });
    console.log(response);
    return Promise.resolve("1");
  };

  const stop = () => {
    console.log("reload");
  };

  return {
    messages,
    bottomRef,
    append,
    reload,
    stop,

    input,
    setInput,
    setMessages,
    isLoading,
  };
}
