"use client";

import { type Message } from "ai/react";

import { useChat } from "../lib/hooks/use-chat";

import { cn } from "../lib/utils";
import { ChatList } from "../components/chat-list";
import { ChatPanel } from "../components/chat-panel";
import { EmptyScreen } from "../components/empty-screen";
import { ChatScrollAnchor } from "../components/chat-scroll-anchor";
import { useLocalStorage } from "../lib/hooks/use-local-storage";

import { useState } from "react";

import CustomLogin from "./custom-login";
import { TooltipProvider } from "../components/ui/tooltip";

const IS_PREVIEW = true;
export interface ChatProps extends React.ComponentProps<"div"> {
  initialMessages?: Message[];
  id?: string;
}

export function Chat({ id, className }: ChatProps) {
  const [logined, setLogined] = useState(false);
  const [previewToken, setPreviewToken] = useLocalStorage<string | null>(
    "ai-token",
    null
  );
  const [previewTokenDialog, setPreviewTokenDialog] = useState(IS_PREVIEW);
  const [previewTokenInput, setPreviewTokenInput] = useState(
    previewToken ?? ""
  );
  const {
    messages,
    append,
    reload,
    stop,
    isLoading,
    input,
    setInput,
    bottomRef,
  } = useChat();
  return false ? (
    <CustomLogin setLogined={setLogined} />
  ) : (
    <div className="flex h-full  w-full flex-auto flex-col rounded-[10px] border border-[#DDE2F0] bg-white shadow-lg md:pt-10">
      <TooltipProvider>
        <div
          className={cn(
            "flex-1 overflow-y-auto  overflow-x-hidden  bg-gray-50 px-5 pt-8  scrollbar scrollbar-w-[6px]",
            className
          )}
        >
          {messages.length ? (
            <>
              <ChatList messages={messages} />
              <ChatScrollAnchor trackVisibility={isLoading} />
            </>
          ) : (
            // <EmptyScreen setInput={setInput} />
            <div />
          )}
          <div ref={bottomRef}></div>
        </div>

        <ChatPanel
          id={id}
          isLoading={isLoading}
          stop={stop}
          append={append}
          reload={reload}
          messages={messages}
          input={input}
          setInput={setInput}
        />
      </TooltipProvider>
    </div>
  );
}
