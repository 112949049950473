export enum Sender {
  USER = "user",
  BOT = "bot",
}

export interface Message {
  content: string;
  sender: Sender;
  id: string;
  type?: "answer" | "question";
}

export const COLLECTION_ID = window?.chatGPTBConfig?.collection || "";

export const BASE_URL = "https://api.gptb.ai";
export const WEBSOCKET_URL = `wss://api.gptb.ai/ws/${COLLECTION_ID}`;
export const TOKEN =
  "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTExNjIzOTAyMn0.dxXcGF5oPXlVnR26X_yxcl9KcdCPOEN07Si_oaJoSgE";
export const reCaptchaV2SiteKey = "6LdGlBooAAAAAI1PzR-AQm5gK4pM-ngq0ioz-7Sd";
export const reCaptchaV3SiteKey = "6LddwxooAAAAAHPau_7PA5MVJcgmZOTT19OMXLSk";
export const reCAPTCHAScriptURL = `https://www.recaptcha.net/recaptcha/enterprise.js?render=${reCaptchaV3SiteKey}`;

export const BREAKWIDTH = 680;
