import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import Draggable, { DraggableEventHandler } from "react-draggable";
import { Toaster } from "react-hot-toast";

import { initializeSentry } from "@/utils/services";
import { opacityTransition } from "@/utils/style";
import {
  calculateDistance,
  getElementTransformValues,
  isClickOutside,
  setElementTransform,
  translateElementToVisibleArea,
} from "@/utils/utils";
import * as Sentry from "@sentry/react";

import { ChatBubbleIcon } from "../icons";
import { Chat } from "../ChatCard/components/chat";

initializeSentry();

const ChatBubble = Sentry.withProfiler(
  () => {
    const [isOpen, setIsOpen] = useState(false);
    const [hasDragged, setHasDragged] = useState(false);
    const bubbleRef = useRef<HTMLDivElement>(null);
    const [chatContainerWidth, setChatContainerWidth] = useState("");
    const [chatContainerHeight, setChatContainerHeight] = useState("");
    const [translateValues, setTranslateValues] = useState([0, 0]);
    const [transitionStyle, setTransitionStyle] = useState("hidden");
    const [startDragPosition, setStartDragPosition] = useState<
      [number, number]
    >([0, 0]);
    const dragDistance = useRef(0);
    const [isBubbleInteracted, setIsBubbleInteracted] = useState(false);

    const handleClickBubble = () => {
      if (dragDistance.current > 5) {
        return;
      }
      setIsBubbleInteracted(true);
      setIsOpen((prev) => !prev);
      setTranslateValues(getElementTransformValues(bubbleRef));
      opacityTransition(setTransitionStyle, true);
    };

    const handleCloseBubble = () => {
      setIsOpen(false);
      opacityTransition(setTransitionStyle, false);
    };

    const handleStartDrag: DraggableEventHandler = (_, data) => {
      setStartDragPosition([data.lastX, data.lastY]);
    };

    const handleDrag = () => {
      setHasDragged(true);
    };

    const handleStopDrag: DraggableEventHandler = (_, data) => {
      dragDistance.current = calculateDistance(startDragPosition, [
        data.lastX,
        data.lastY,
      ]);

      setHasDragged(false);
      handleClickBubble();
    };

    const handleResize = () => {
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;

      setChatContainerHeight(
        screenWidth < 640 || screenHeight < 840
          ? "h-[560px]"
          : "h-chat-container"
      );
      setChatContainerWidth(
        screenWidth < 640 ? "w-[320px]" : "w-chat-container"
      );
    };

    useEffect(() => {
      if (isOpen) {
        translateElementToVisibleArea(bubbleRef);
      } else {
        setElementTransform(bubbleRef, translateValues);
      }
    }, [isOpen, translateValues]);

    useEffect(() => {
      // Set initial size
      handleResize();

      const handleClickOutside = (event: MouseEvent) => {
        if (isClickOutside(bubbleRef, event)) {
          handleCloseBubble();
        }
      };

      document.addEventListener("click", handleClickOutside);
      window.addEventListener("resize", handleResize);
      window.addEventListener("orientationchange", handleResize);

      return () => {
        document.removeEventListener("click", handleClickOutside);
        window.removeEventListener("resize", handleResize);
        window.addEventListener("orientationchange", handleResize);
      };
    }, []);

    return createPortal(
      <Draggable
        onStart={handleStartDrag}
        onDrag={handleDrag}
        onStop={handleStopDrag}
        disabled={isOpen}
      >
        <div
          ref={bubbleRef}
          className={classNames("fixed bottom-4 right-4 z-10", {
            " transition-transform duration-300": !hasDragged,
          })}
        >
          <Toaster />
          <div
            className={classNames(
              ` transition duration-150 ${chatContainerWidth} ${chatContainerHeight} ${transitionStyle}`
            )}
          >
            {isBubbleInteracted && (
              // <ChatArea handleCloseBubble={handleCloseBubble} />
              <div className=" h-full w-full">
                <Chat id={"10086"} />
              </div>
              // <div>1</div>
            )}
          </div>

          <button
            className={classNames(
              "overflow-hidden drop-shadow-bubble hover:scale-105 active:scale-110",
              {
                hidden: isOpen,
              }
            )}
          >
            <ChatBubbleIcon />
          </button>
        </div>
      </Draggable>,
      document.body
    );
  },
  {
    name: "ChatBubble",
  }
);
export default ChatBubble;
